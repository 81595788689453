const swiperProducts = new Swiper(".js-horScroll-download", {
	slidesPerView: "3",
	spaceBetween: 20,
	breakpoints: {
		319: {
			slidesPerView: "auto",
			spaceBetween: 10,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
	},
});
